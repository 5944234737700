<template>
  <q-form ref="editForm">
    <c-card title="감사계획 상세" class="cardClassDetailForm">
      <template slot="card-button">
        <q-btn-group outline >
          <c-btn
            v-show="editable  && popupParam.selfInspectionStatusCd == 'SISC000001'"
            :url="saveUrl"
            :isSubmit="isSave"
            :param="data"
            :mappingType="mappingType"
            label="LBLSAVE"
            icon="save"
            @beforeAction="saveData"
            @btnCallback="saveCallback" />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-text
            :required="true"
            :editable="editable"
            label="감사명"
            name="selfInspectionTitle"
            v-model="data.selfInspectionTitle">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :required="true"
            :editable="editable"
            type="year"
            label="감사년도"
            name="selfInspectionYear"
            v-model="data.selfInspectionYear"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-2">
          <c-select
            :required="true"
            codeGroupCd="SELF_INSPECTION_TYPE_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="selfInspectionTypeCd"
            label="자체감사종류"
            v-model="data.selfInspectionTypeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-2">
          <c-plant
            :required="true"
            :editable="editable"
            type="edit"
            name="plantCd"
            v-model="data.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-2">
          <c-select
            :editable="false"
            type="none"
            :comboItems="revisionItems"
            itemText="codeName"
            itemValue="code"
            label="평가항목 개정번호"
            name="sysRevision"
            v-model="data.sysRevision">
          </c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-card title="감사대상" class="cardClassDetailForm">
            <template slot="card-detail">
              <div class="col-12">
                <c-text
                  :editable="editable"
                  label="감사대상개요"
                  name="remark"
                  v-model="data.remark">
                </c-text>
              </div>
              <div class="col-4">
                <c-table
                  ref="grid1"
                  title="대상공정"
                  tableId="grid1"
                  :columns="grid1.columns"
                  :data="grid1.data"
                  selection="multiple"
                  rowKey="processCd"
                  :isFullScreen="false"
                  :columnSetting="false"
                  :filtering="false"
                  :usePaging="false"
                  :hideBottom="true"
                  gridHeight="300px"
                >
                  <!-- 버튼 영역 -->
                  <template slot="table-button">
                    <q-btn-group outline >
                      <c-btn v-if="editable && popupParam.selfInspectionResultId && popupParam.selfInspectionStatusCd == 'SISC000001'" icon="add" @btnClicked="addrow1" />
                      <c-btn v-if="editable && popupParam.selfInspectionResultId && popupParam.selfInspectionStatusCd == 'SISC000001'" icon="remove" @btnClicked="removerow1" />
                    </q-btn-group>
                  </template>
                </c-table>
              </div>
              <div class="col-4">
                <c-table
                  ref="grid2"
                  title="대상설비"
                  tableId="grid2"
                  :columns="grid2.columns"
                  :data="grid2.data"
                  selection="multiple"
                  rowKey="equipmentCd"
                  :isFullScreen="false"
                  :columnSetting="false"
                  :filtering="false"
                  :usePaging="false"
                  :hideBottom="true"
                  gridHeight="300px"
                >
                  <!-- 버튼 영역 -->
                  <template slot="table-button">
                    <q-btn-group outline >
                      <c-btn v-if="editable && popupParam.selfInspectionResultId && popupParam.selfInspectionStatusCd == 'SISC000001'" icon="add" @btnClicked="addrow2" />
                      <c-btn v-if="editable && popupParam.selfInspectionResultId && popupParam.selfInspectionStatusCd == 'SISC000001'" icon="remove" @btnClicked="removerow2" />
                    </q-btn-group>
                  </template>
                </c-table>
              </div>
              <div class="col-4">
                <c-table
                  ref="grid3"
                  title="대상부서"
                  tableId="grid3"
                  :columns="grid3.columns"
                  :data="grid3.data"
                  selection="multiple"
                  rowKey="deptCd"
                  :isFullScreen="false"
                  :columnSetting="false"
                  :filtering="false"
                  :usePaging="false"
                  :hideBottom="true"
                  gridHeight="300px"
                >
                  <!-- 버튼 영역 -->
                  <template slot="table-button">
                    <q-btn-group outline >
                      <c-btn v-if="editable && popupParam.selfInspectionResultId && popupParam.selfInspectionStatusCd == 'SISC000001'" icon="add" @btnClicked="addrow3" />
                      <c-btn v-if="editable && popupParam.selfInspectionResultId && popupParam.selfInspectionStatusCd == 'SISC000001'" icon="remove" @btnClicked="removerow3" />
                    </q-btn-group>
                  </template>
                </c-table>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-card title="감사수행일정" class="cardClassDetailForm">
            <template slot="card-detail">
              <div class="col-12">
                <c-datepicker
                  :required="true"
                  :editable="editable"
                  :range="true"
                  label="감사기간"
                  name="selfInspectionDt"
                  v-model="data.selfInspectionDt"
                />
              </div>
              <div class="col-12">
                <c-table
                  ref="grid4"
                  title="일자별 수행내역"
                  tableId="grid4"
                  :columns="grid4.columns"
                  :data="grid4.data"
                  selection="multiple"
                  rowKey="selfInspectionExecId"
                  :isFullScreen="false"
                  :columnSetting="false"
                  :filtering="false"
                  :usePaging="false"
                  :hideBottom="true"
                  gridHeight="300px"
                >
                  <!-- 버튼 영역 -->
                  <template slot="table-button">
                    <q-btn-group outline >
                      <c-btn v-if="editable && popupParam.selfInspectionResultId && popupParam.selfInspectionStatusCd == 'SISC000001'" icon="add" @btnClicked="addrow4" />
                      <c-btn v-if="editable && popupParam.selfInspectionResultId && popupParam.selfInspectionStatusCd == 'SISC000001'" icon="save" @btnClicked="saveRow4"/>
                      <c-btn v-if="editable && popupParam.selfInspectionResultId && popupParam.selfInspectionStatusCd == 'SISC000001'" icon="remove" @btnClicked="removerow4" />
                    </q-btn-group>
                  </template>
                </c-table>
              </div>
            </template>
          </c-card>
        </div>
      </template>
    </c-card>
    <c-dialog :param="popupOptions"></c-dialog>
  </q-form>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'hazop-plan-info',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        selfInspectionResultId: '',
        selfInspectionStatusCd: '',
        sysRevision: '',
      }),
    },
  },
  data() {
    return {
      revisionItems: [],
      data: {
        selfInspectionResultId: '',  // 자체감사 계획 일련번호
        selfInspectionTitle: '',  // 감사명
        selfInspectionYear: '',  // 감사년도
        selfInspectionTypeCd: null,  // 감사종류
        plantCd: null,  // 해당사업장
        remark: '',  // 감사대상 개요
        selfInspectionStartDt: '',  // 감사기간-시작일
        selfInspectionEndDt: '',  // 감사기간-종료일
        selfInspectionStatusCd: '',  // 감사진행상태-계획중,계획완료,감사완료
        selfInspectionResultOpinion: '',  // 감사원소견
        selfInspectionResultTotalOpinion: '',  // 감사 결과 종합의견
        selfInspectionResultCorrect: '',  // 시정 및 권고 요약
        selfInspectionDt: [],
        sysRevision: '',  // 평가항목 개정번호
      },
      grid1: {
        columns: [
          {
            name: 'processName',
            field: 'processName',
            label: '상위공정명',
            align: 'left',
            sortable: false,
          },
        ],
        data: [],
      },
      grid2: {
        columns: [
          {
            name: 'equipmentName',
            field: 'equipmentName',
            label: '설비명',
            align: 'left',
            sortable: false,
          },
        ],
        data: [],
      },
      grid3: {
        columns: [
          {
            name: 'deptName',
            field: 'deptName',
            label: 'LBLDEPT',
            align: 'left',
            sortable: false,
          },
        ],
        data: [],
      },
      grid4: {
        columns: [
          {
            required: true,
            name: 'execDt',
            field: 'execDt',
            label: '수행일자',
            align: 'center',
            style: 'width: 120px',
            type: 'date',
            sortable: false,
          },
          {
            required: true,
            name: 'execContent',
            field: 'execContent',
            label: '감사내역',
            align: 'left',
            type: 'text',
            sortable: false,
          },
          {
            name: 'execUser',
            field: 'execUser',
            label: '참석자',
            align: 'left',
            style: 'width: 200px',
            type: 'text',
            sortable: false,
          },
        ],
        data: [],
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '50%',
        isFull: false,
        param: {},
        closeCallback: null,
      },
      editable: true,
      infoUrl: '',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      list1Url: '',
      insert1Url: '',
      delete1Url: '',
      list2Url: '',
      insert2Url: '',
      delete2Url: '',
      list3Url: '',
      insert3Url: '',
      delete3Url: '',
      list4Url: '',
      insert4Url: '',
      delete4Url: '',
      revisionUrl: '',
      saveUrl: transactionConfig.sop.pca.result.info.insert.url,
      mappingType: 'POST',
      isSave: false,
      isSaveEnd: false,
      isdelete: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.revisionUrl = selectConfig.sop.pca.item.revs.item;
      this.infoUrl = selectConfig.sop.pca.result.info.get.url;
      this.insertUrl = transactionConfig.sop.pca.result.info.insert.url;
      this.updateUrl = transactionConfig.sop.pca.result.info.update.url;
      this.deleteUrl = transactionConfig.sop.pca.result.info.delete.url;
      this.list1Url = selectConfig.sop.pca.result.process.list.url;
      this.insert1Url = transactionConfig.sop.pca.result.process.save.url;
      this.delete1Url = transactionConfig.sop.pca.result.process.delete.url;
      this.list2Url = selectConfig.sop.pca.result.equipment.list.url;
      this.insert2Url = transactionConfig.sop.pca.result.equipment.save.url;
      this.delete2Url = transactionConfig.sop.pca.result.equipment.delete.url;
      this.list3Url = selectConfig.sop.pca.result.dept.list.url;
      this.insert3Url = transactionConfig.sop.pca.result.dept.save.url;
      this.delete3Url = transactionConfig.sop.pca.result.dept.delete.url;
      this.list4Url = selectConfig.sop.pca.result.exec.list.url;
      this.insert4Url = transactionConfig.sop.pca.result.exec.save.url;
      this.delete4Url = transactionConfig.sop.pca.result.exec.delete.url;
      this.getRevision();
    },
    getRevision() {
      this.$http.url = this.revisionUrl;
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.revisionItems = _result.data;
        if (!this.popupParam.selfInspectionResultId) {
          this.data.sysRevision = this.revisionItems[0].code;
          this.popupParam.sysRevision = this.revisionItems[0].code;
          this.popupParam.selfInspectionStatusCd = 'SISC000001';
        } else {
          this.getInfo();
          this.getTaget1();
          this.getTaget2();
          this.getTaget3();
          this.getTaget4();
        }
      },);
    },
    getInfo() {
      this.$http.url = this.infoUrl;
      this.$http.type = 'GET';
      this.$http.param = {selfInspectionResultId: this.popupParam.selfInspectionResultId};
      this.$http.request((_result) => {
        this.data = _result.data;
        this.data.sysRevision = this.data.sysRevision + '';
        this.data.selfInspectionDt = [this.data.selfInspectionStartDt, this.data.selfInspectionEndDt];
      },);
    },
    getTaget1() {
      this.$http.url = this.list1Url;
      this.$http.type = 'GET';
      this.$http.param = {selfInspectionResultId: this.popupParam.selfInspectionResultId};
      this.$http.request((_result) => {
        this.grid1.data = _result.data;
      },);
    },
    getTaget2() {
      this.$http.url = this.list2Url;
      this.$http.type = 'GET';
      this.$http.param = {selfInspectionResultId: this.popupParam.selfInspectionResultId};
      this.$http.request((_result) => {
        this.grid2.data = _result.data;
      },);
    },
    getTaget3() {
      this.$http.url = this.list3Url;
      this.$http.type = 'GET';
      this.$http.param = {selfInspectionResultId: this.popupParam.selfInspectionResultId};
      this.$http.request((_result) => {
        this.grid3.data = _result.data;
      },);
    },
    getTaget4() {
      this.$http.url = this.list4Url;
      this.$http.type = 'GET';
      this.$http.param = {selfInspectionResultId: this.popupParam.selfInspectionResultId};
      this.$http.request((_result) => {
        this.grid4.data = _result.data;
      },);
    },
    saveData() {
      if (this.popupParam.selfInspectionResultId) {
        this.saveUrl = this.updateUrl;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.mappingType = 'POST';
        this.data.selfInspectionStatusCd = 'SISC000001'; // 계획중
      }
      let saveMsg = 'MSGSAVE';
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: saveMsg,
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.regUserId = this.$store.getters.user.userId;
              this.data.chgUserId = this.$store.getters.user.userId;
              this.data.selfInspectionStartDt = this.data.selfInspectionDt[0];
              this.data.selfInspectionEndDt = this.data.selfInspectionDt[1];
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.selfInspectionResultId = result.data.selfInspectionResultId;
      this.popupParam.selfInspectionStatusCd = result.data.selfInspectionStatusCd;
      this.getInfo();
    },
    addrow1() {
      this.popupOptions.title = '상위공정 검색';
      this.popupOptions.param = {
        type: 'multiple',
        processLevelCd: '10'
      };
      this.popupOptions.target = () => import(`${'@/pages/common/process/processPop.vue'}`);
      this.popupOptions.width = '50%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeProcess;
    },
    closeProcess(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        let saveData = [];
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.grid1.data, { processCd: item.processCd }) === -1) {
            saveData.push({
              selfInspectionResultId: this.popupParam.selfInspectionResultId,  // 자체감사 계획 일련번호
              processCd: item.processCd,  // 대상공정코드
            })
          }
        })
        this.$http.url = this.insert1Url;
        this.$http.type = 'POST';
        this.$http.param = saveData;
        this.$http.request(() => {
          this.getTaget1();
        });
      }
    },
    removerow1() {
      let selectData = this.$refs['grid1'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGREMOVE', // 삭제하시겠습니까?,
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.delete1Url;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.grid1.data = this.$_.reject(this.grid1.data, item);
              })
              this.$refs['grid1'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    addrow2() {
      this.popupOptions.title = '설비 검색';
      this.popupOptions.param = {
        type: 'multiple',
        plantCd: this.data.plantCd,
      };
      this.popupOptions.target = () => import(`${'@/pages/common/equip/equipPop.vue'}`);
      this.popupOptions.width = '50%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeEquipmenet;
    },
    closeEquipmenet(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        let saveData = [];
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.grid2.data, { equipmentCd: item.equipmentCd }) === -1) {
            saveData.push({
              selfInspectionResultId: this.popupParam.selfInspectionResultId,  // 자체감사 계획 일련번호
              equipmentCd: item.equipmentCd,
            })
          }
        })
        this.$http.url = this.insert2Url;
        this.$http.type = 'POST';
        this.$http.param = saveData;
        this.$http.request(() => {
          this.getTaget2();
        });
      }
    },
    removerow2() {
      let selectData = this.$refs['grid2'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGREMOVE', // 삭제하시겠습니까?,
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.delete2Url;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.grid2.data = this.$_.reject(this.grid2.data, item);
              })
              this.$refs['grid2'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    addrow3() {
      this.popupOptions.title = '부서 검색';
      this.popupOptions.param = {
        type: 'multiple',
        plantCd: this.data.plantCd,
      };
      this.popupOptions.target = () => import(`${'@/pages/common/dept/deptMultiPop.vue'}`);
      this.popupOptions.width = '50%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeDept;
    },
    closeDept(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        let saveData = [];
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.grid3.data, { deptCd: item.deptCd }) === -1) {
            saveData.push({
              selfInspectionResultId: this.popupParam.selfInspectionResultId,  // 자체감사 계획 일련번호
              deptCd: item.deptCd,
            })
          }
        })
        this.$http.url = this.insert3Url;
        this.$http.type = 'POST';
        this.$http.param = saveData;
        this.$http.request(() => {
          this.getTaget3();
        });
      }
    },
    removerow3() {
      let selectData = this.$refs['grid3'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGREMOVE', // 삭제하시겠습니까?,
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.delete3Url;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.grid3.data = this.$_.reject(this.grid3.data, item);
              })
              this.$refs['grid3'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    addrow4() {
      this.grid4.data.push({
        editFlag: 'C',
        selfInspectionResultId: this.popupParam.selfInspectionResultId,  // 자체감사 계획 일련번호
        selfInspectionExecId: uid(),  // 일자별 수행내역 일련번호
        execDt: this.$comm.getToday(),  // 수행일자
        execContent: '',  // 감사내역
        execUser: '',  // 참석자
      })
    },
    saveRow4() {
      let checkItem = ['execDt','execContent']
      let isConti = true;
      this.$_.forEach(this.grid4.data, item => {
        this.$_.forEach(checkItem, check => {
          if (!item[check]) {
            isConti = false;
            return false;
          }
        })
      });
      if (!isConti) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: '필수 입력값을 입력해 주세요. [수행일자, 감사내역]',
          type: 'warning', // success / info / warning / error
        });
      } else {
        let saveData = this.grid4.data.filter( x => {
          return x.editFlag == 'C' || x.editFlag == 'U'
        });
        if(saveData.length > 0) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            type: 'info',
            // 확인 callback 함수
            confirmCallback: () => {
              this.$http.url = this.insert4Url;
              this.$http.type = 'POST';
              this.$http.param = saveData;
              this.$http.request(() => {
                window.getApp.$emit('APP_REQUEST_SUCCESS');
                this.getTaget4();
              });
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('ALERT', {
            title: '안내 ', /* 안내 */
            message: 'MSGNOCHANGEDATA', // 변경된 데이터가 없습니다., /* 변경된 데이터가 없습니다. */
            type: 'info', // success / info / warning / error
          });
        }
      }
    },
    removerow4() {
      let selectData = this.$refs['grid4'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGREMOVE', // 삭제하시겠습니까?,
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.delete4Url;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.grid4.data = this.$_.reject(this.grid4.data, item);
              })
              this.$refs['grid4'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
  }
};
</script>
